import { FC, SVGProps } from 'react';

const Stack11: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      {...props}
    >
      <path
        d="M10 2.2C10 2.86274 7.98528 3.4 5.5 3.4C3.01472 3.4 1 2.86274 1 2.2M10 2.2C10 1.53726 7.98528 1 5.5 1C3.01472 1 1 1.53726 1 2.2M10 2.2V7.8C10 8.464 8 9 5.5 9C3 9 1 8.464 1 7.8V2.2M10 5C10 5.664 8 6.2 5.5 6.2C3 6.2 1 5.664 1 5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2.2C10 2.86274 7.98528 3.4 5.5 3.4C3.01472 3.4 1 2.86274 1 2.2M10 2.2C10 1.53726 7.98528 1 5.5 1C3.01472 1 1 1.53726 1 2.2M10 2.2V7.8C10 8.464 8 9 5.5 9C3 9 1 8.464 1 7.8V2.2M10 5C10 5.664 8 6.2 5.5 6.2C3 6.2 1 5.664 1 5"
        stroke="url(#paint0_linear_2699_721)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2699_721"
          x1="5.5"
          y1="1"
          x2="5.5"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Stack11;
