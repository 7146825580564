const API_URL =
  process.env.REACT_APP_BACKEND_API_URL ||
  process.env.REACT_APP_API_URL ||
  'https://api.4.fun';

export interface CreateTokenFormData {
  tokenDescription: string;
  tokenImage: FileList;
  tokenName: string;
  tokenTicker: string;

  telegramLink?: string;
  twitterLink?: string;
  website?: string;
}

export const uploadIpfsData = async (data: CreateTokenFormData) => {
  const formData = new FormData();
  formData.append('file', data.tokenImage[0]);
  formData.append('name', data.tokenName);
  formData.append('description', data.tokenDescription);

  if (data.telegramLink) {
    formData.append('socialLinks[telegram]', data.telegramLink);
  }
  if (data.twitterLink) {
    formData.append('socialLinks[twitter]', data.twitterLink);
  }
  if (data.website) {
    formData.append('socialLinks[site]', data.website);
  }

  const response = await fetch(`${API_URL}/backend/ipfs/upload`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.validation.join(', '));
  }

  return await response.json();
};
