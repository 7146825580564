import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Root } from './routes/Root';
import { Detail } from './routes/Detail';
import { Create } from './routes/Create';
import { User } from './routes/User';
import { Layout } from './components/Layout';
import { Wallet } from './providers/wallet';
import DataProvider from './providers/data';
import { Landing } from './routes/Landing';
import { QueryProvider } from './providers/query';
import { RefcodeProvider } from './providers/refcode';
import SearchProvider from './providers/search';
import ModalProvider from './providers/modal';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d795f5a65396c2de91ac5f294ac5ee31@o4508320161333248.ingest.de.sentry.io/4508320164806736",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/l\.4\.fun/, /^https:\/\/fourfun\.pages\.dev/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV ?? 'production',
});

const routerDev = createBrowserRouter([
  {
    path: '/landing',
    element: (
      <RefcodeProvider>
        <Landing />
      </RefcodeProvider>
    ),
  },
  {
    path: '/',
    element: (
      <Layout>
        <Root />
      </Layout>
    ),
  },
  {
    path: '/:mint',
    element: (
      <Layout>
        <Detail />
      </Layout>
    ),
  },
  {
    path: '/create',
    element: (
      <Layout>
        <Create />
      </Layout>
    ),
  },
  {
    path: '/user/:address',
    element: (
      <Layout>
        <User />
      </Layout>
    ),
  },
]);

const routerProd = createBrowserRouter([
  {
    path: '/',
    element: (
      <RefcodeProvider>
        <Landing />
      </RefcodeProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ModalProvider>
      <Wallet>
        <SearchProvider>
          <DataProvider>
            <QueryProvider>
              <RouterProvider
                router={
                  process.env.REACT_APP_SHOW_LANDING === '1'
                    ? routerProd
                    : routerDev
                }
              />
            </QueryProvider>
          </DataProvider>
        </SearchProvider>
      </Wallet>
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
