import { FC, SVGProps } from 'react';

const Success: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="18"
        height="18"
        rx="9"
        stroke="currentColor"
      />
      <path
        d="M13 7L8.1875 12L6 9.72727"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Success;
