import { Order, TokenItem, TokenItemV2 } from '../interfaces/token';

export const MOCK_TOKEN: TokenItem = {
  token: {
    mint: 'mint',
    creator: 'creator',
    meta: {
      name: 'name',
      symbol: 'symbol',
      imageUrl: '/doge.png',
      description: 'description',
      socialLinks: {},
    },
    released_at: 'released_at',
    created_at: 'created_at',
    id: 1,
    metadataUrl: 'metadataUrl',
    transaction_signature: 'transaction_signature',
    instruction_index: 1,
    internal_instruction_index: 1,
    time: 'time',
    updated_at: 'updated_at',
  },
  stats: {
    price: 1,
    marketCap: 'marketCap',
    liquidity: 'liquidity',
    bondingRate: 1,
    threads: 1,
    makers: 1,
    transactions: {
      buy: 1,
      sell: 1,
    },
    volume: 'volume',
    changes: {
      period60s: 1,
      period5m: 1,
      period15m: 1,
      period30m: 1,
      period1h: 1,
    },
    latestOrderTime: 'latestOrderTime',
  },
};

export const MOCK_ORDERS: Order[] = [
  {
    time: new Date(),
    bonding_rate: 1,
    maker: 'maker1',
    token_amount_in: '1',
    token_amount_out: '1',
    quote_amount_in: '1',
    quote_amount_out: '1',
    instruction_index: 1,
    internal_instruction_index: 1,
    curve_balance: 1,
    token_mint: 'token_mint1',
    transaction_signature: 'transaction_signature1',
    tokens_in_pool: 1,
  },
];

export const MOCK_TOKENS_V2: TokenItemV2[] = [
  {
    mint: 'mint1',
    value: 'value1',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap1',
      liquidity: 'liquidity1',
      bondingRate: 1,
      threads: 1,
      makers: 1,
      transactions: {
        buy: 1,
        sell: 1,
      },
      volume: 'volume1',
      changes: {
        period60s: 1,
        period5m: 1,
        period15m: 1,
        period30m: 1,
        period1h: 1,
      },
      latestOrderTime: 'latestOrderTime1',
    },
    tokenDetails: {
      mint: 'mint1',
      creator: 'creator1',
      meta: {
        name: 'name1',
        symbol: 'symbol1',
        imageUrl: '/doge.png',
        description: 'description1',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl1',
      transaction_signature: 'transaction_signature1',
      instruction_index: 1,
      internal_instruction_index: 1,
      time: 'time1',
      released_at: 'released_at1',
      id: 1,
      created_at: 'created_at1',
      updated_at: 'updated_at1',
    },
  },
  {
    mint: 'mint2',
    value: 'value2',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap2',
      liquidity: 'liquidity2',
      bondingRate: 2,
      threads: 2,
      makers: 2,
      transactions: {
        buy: 2,
        sell: 2,
      },
      volume: 'volume2',
      changes: {
        period60s: 2,
        period5m: 2,
        period15m: 2,
        period30m: 2,
        period1h: 2,
      },
      latestOrderTime: 'latestOrderTime2',
    },
    tokenDetails: {
      mint: 'mint2',
      creator: 'creator2',
      meta: {
        name: 'name2',
        symbol: 'symbol2',
        imageUrl: '/doge.png',
        description: 'description2',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl2',
      transaction_signature: 'transaction_signature2',
      instruction_index: 2,
      internal_instruction_index: 2,
      time: 'time2',
      released_at: 'released_at2',
      id: 2,
      created_at: 'created_at2',
      updated_at: 'updated_at2',
    },
  },
  {
    mint: 'mint3',
    value: 'value3',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap3',
      liquidity: 'liquidity3',
      bondingRate: 3,
      threads: 3,
      makers: 3,
      transactions: {
        buy: 3,
        sell: 3,
      },
      volume: 'volume3',
      changes: {
        period60s: 3,
        period5m: 3,
        period15m: 3,
        period30m: 3,
        period1h: 3,
      },
      latestOrderTime: 'latestOrderTime3',
    },
    tokenDetails: {
      mint: 'mint3',
      creator: 'creator3',
      meta: {
        name: 'name3',
        symbol: 'symbol3',
        imageUrl: '/doge.png',
        description: 'description3',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl3',
      transaction_signature: 'transaction_signature3',
      instruction_index: 3,
      internal_instruction_index: 3,
      time: 'time3',
      released_at: 'released_at3',
      id: 3,
      created_at: 'created_at3',
      updated_at: 'updated_at3',
    },
  },
  {
    mint: 'mint4',
    value: 'value4',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap4',
      liquidity: 'liquidity4',
      bondingRate: 4,
      threads: 4,
      makers: 4,
      transactions: {
        buy: 4,
        sell: 4,
      },
      volume: 'volume4',
      changes: {
        period60s: 4,
        period5m: 4,
        period15m: 4,
        period30m: 4,
        period1h: 4,
      },
      latestOrderTime: 'latestOrderTime4',
    },
    tokenDetails: {
      mint: 'mint4',
      creator: 'creator4',
      meta: {
        name: 'name4',
        symbol: 'symbol4',
        imageUrl: '/doge.png',
        description: 'description4',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl4',
      transaction_signature: 'transaction_signature4',
      instruction_index: 4,
      internal_instruction_index: 4,
      time: 'time4',
      released_at: 'released_at4',
      id: 4,
      created_at: 'created_at4',
      updated_at: 'updated_at4',
    },
  },
  {
    mint: 'mint5',
    value: 'value5',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap5',
      liquidity: 'liquidity5',
      bondingRate: 5,
      threads: 5,
      makers: 5,
      transactions: {
        buy: 5,
        sell: 5,
      },
      volume: 'volume5',
      changes: {
        period60s: 5,
        period5m: 5,
        period15m: 5,
        period30m: 5,
        period1h: 5,
      },
      latestOrderTime: 'latestOrderTime5',
    },
    tokenDetails: {
      mint: 'mint5',
      creator: 'creator5',
      meta: {
        name: 'name5',
        symbol: 'symbol5',
        imageUrl: '/doge.png',
        description: 'description5',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl5',
      transaction_signature: 'transaction_signature5',
      instruction_index: 5,
      internal_instruction_index: 5,
      time: 'time5',
      released_at: 'released_at5',
      id: 5,
      created_at: 'created_at5',
      updated_at: 'updated_at5',
    },
  },
  {
    mint: 'mint6',
    value: 'value6',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap6',
      liquidity: 'liquidity6',
      bondingRate: 6,
      threads: 6,
      makers: 6,
      transactions: {
        buy: 6,
        sell: 6,
      },
      volume: 'volume6',
      changes: {
        period60s: 6,
        period5m: 6,
        period15m: 6,
        period30m: 6,
        period1h: 6,
      },
      latestOrderTime: 'latestOrderTime6',
    },
    tokenDetails: {
      mint: 'mint6',
      creator: 'creator6',
      meta: {
        name: 'name6',
        symbol: 'symbol6',
        imageUrl: '/doge.png',
        description: 'description6',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl6',
      transaction_signature: 'transaction_signature6',
      instruction_index: 6,
      internal_instruction_index: 6,
      time: 'time6',
      released_at: 'released_at6',
      id: 6,
      created_at: 'created_at6',
      updated_at: 'updated_at6',
    },
  },
  {
    mint: 'mint7',
    value: 'value7',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap7',
      liquidity: 'liquidity7',
      bondingRate: 7,
      threads: 7,
      makers: 7,
      transactions: {
        buy: 7,
        sell: 7,
      },
      volume: 'volume7',
      changes: {
        period60s: 7,
        period5m: 7,
        period15m: 7,
        period30m: 7,
        period1h: 7,
      },
      latestOrderTime: 'latestOrderTime7',
    },
    tokenDetails: {
      mint: 'mint7',
      creator: 'creator7',
      meta: {
        name: 'name7',
        symbol: 'symbol7',
        imageUrl: '/doge.png',
        description: 'description7',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl7',
      transaction_signature: 'transaction_signature7',
      instruction_index: 7,
      internal_instruction_index: 7,
      time: 'time7',
      released_at: 'released_at7',
      id: 7,
      created_at: 'created_at7',
      updated_at: 'updated_at7',
    },
  },
  {
    mint: 'mint8',
    value: 'value8',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap8',
      liquidity: 'liquidity8',
      bondingRate: 8,
      threads: 8,
      makers: 8,
      transactions: {
        buy: 8,
        sell: 8,
      },
      volume: 'volume8',
      changes: {
        period60s: 8,
        period5m: 8,
        period15m: 8,
        period30m: 8,
        period1h: 8,
      },
      latestOrderTime: 'latestOrderTime8',
    },
    tokenDetails: {
      mint: 'mint8',
      creator: 'creator8',
      meta: {
        name: 'name8',
        symbol: 'symbol8',
        imageUrl: '/doge.png',
        description: 'description8',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl8',
      transaction_signature: 'transaction_signature8',
      instruction_index: 8,
      internal_instruction_index: 8,
      time: 'time8',
      released_at: 'released_at8',
      id: 8,
      created_at: 'created_at8',
      updated_at: 'updated_at8',
    },
  },
  {
    mint: 'mint9',
    value: 'value9',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap9',
      liquidity: 'liquidity9',
      bondingRate: 9,
      threads: 9,
      makers: 9,
      transactions: {
        buy: 9,
        sell: 9,
      },
      volume: 'volume9',
      changes: {
        period60s: 9,
        period5m: 9,
        period15m: 9,
        period30m: 9,
        period1h: 9,
      },
      latestOrderTime: 'latestOrderTime9',
    },
    tokenDetails: {
      mint: 'mint9',
      creator: 'creator9',
      meta: {
        name: 'name9',
        symbol: 'symbol9',
        imageUrl: '/doge.png',
        description: 'description9',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl9',
      transaction_signature: 'transaction_signature9',
      instruction_index: 9,
      internal_instruction_index: 9,
      time: 'time9',
      released_at: 'released_at9',
      id: 9,
      created_at: 'created_at9',
      updated_at: 'updated_at9',
    },
  },
  {
    mint: 'mint10',
    value: 'value10',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap10',
      liquidity: 'liquidity10',
      bondingRate: 10,
      threads: 10,
      makers: 10,
      transactions: {
        buy: 10,
        sell: 10,
      },
      volume: 'volume10',
      changes: {
        period60s: 10,
        period5m: 10,
        period15m: 10,
        period30m: 10,
        period1h: 10,
      },
      latestOrderTime: 'latestOrderTime10',
    },
    tokenDetails: {
      mint: 'mint10',
      creator: 'creator10',
      meta: {
        name: 'name10',
        symbol: 'symbol10',
        imageUrl: '/doge.png',
        description: 'description10',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl10',
      transaction_signature: 'transaction_signature10',
      instruction_index: 10,
      internal_instruction_index: 10,
      time: 'time10',
      released_at: 'released_at10',
      id: 10,
      created_at: 'created_at10',
      updated_at: 'updated_at10',
    },
  },
  {
    mint: 'mint11',
    value: 'value11',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap11',
      liquidity: 'liquidity11',
      bondingRate: 11,
      threads: 11,
      makers: 11,
      transactions: {
        buy: 11,
        sell: 11,
      },
      volume: 'volume11',
      changes: {
        period60s: 11,
        period5m: 11,
        period15m: 11,
        period30m: 11,
        period1h: 11,
      },
      latestOrderTime: 'latestOrderTime11',
    },
    tokenDetails: {
      mint: 'mint11',
      creator: 'creator11',
      meta: {
        name: 'name11',
        symbol: 'symbol11',
        imageUrl: '/doge.png',
        description: 'description11',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl11',
      transaction_signature: 'transaction_signature11',
      instruction_index: 11,
      internal_instruction_index: 11,
      time: 'time11',
      released_at: 'released_at11',
      id: 11,
      created_at: 'created_at11',
      updated_at: 'updated_at11',
    },
  },
  {
    mint: 'mint12',
    value: 'value12',
    changes: '',
    tokenStats: {
      price: 1,
      marketCap: 'marketCap12',
      liquidity: 'liquidity12',
      bondingRate: 12,
      threads: 12,
      makers: 12,
      transactions: {
        buy: 12,
        sell: 12,
      },
      volume: 'volume12',
      changes: {
        period60s: 12,
        period5m: 12,
        period15m: 12,
        period30m: 12,
        period1h: 12,
      },
      latestOrderTime: 'latestOrderTime12',
    },
    tokenDetails: {
      mint: 'mint12',
      creator: 'creator12',
      meta: {
        name: 'name12',
        symbol: 'symbol12',
        imageUrl: '/doge.png',
        description: 'description12',
        socialLinks: {},
      },
      metadataUrl: 'metadataUrl12',
      transaction_signature: 'transaction_signature12',
      instruction_index: 12,
      internal_instruction_index: 12,
      time: 'time12',
      released_at: 'released_at12',
      id: 12,
      created_at: 'created_at12',
      updated_at: 'updated_at12',
    },
  },
];

export const USE_MOCK_DATA = false;
