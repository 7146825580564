import { FC, ReactNode, useEffect, useState, useRef } from 'react';
import styles from './styles.module.css';
import { Button } from '../Button';
import ChevronDown from '../svg/ChevronDown';
import ChevronUp from '../svg/ChevronUp';
import ChevronDown8 from '../svg/ChevronDown8';
import ChevronUp8 from '../svg/ChevronUp8';

type SelectOption = {
  label: string;
  value: any;
};

interface SelectProps {
  options?: SelectOption[];
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  className?: string;
  style?: string;
  prefix?: ReactNode;
}

export const Select: FC<SelectProps> = (props: SelectProps) => {
  const {
    options = [],
    value = null,
    onChange = () => {},
    disabled = false,
    className = '',
    style = {},
    prefix,
  } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Add event listener click to close dropdown when click outside
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className={`${styles.select} ${className}`} style={style} ref={ref}>
      <Button
        disabled={disabled}
        onClick={() => setOpen(!open)}
        className={styles.selectButton}
      >
        <div className={styles.text}>
          {prefix}
          {options.find((option) => option.value === value)?.label || ''}
        </div>
        {open ? (
          <>
            <ChevronUp className={styles.chevron} />
            <ChevronUp8 className={styles.chevronMobile} />
          </>
        ) : (
          <>
            <ChevronDown className={styles.chevron} />
            <ChevronDown8 className={styles.chevronMobile} />
          </>
        )}
      </Button>
      <div className={styles.dropdownWrapper}>
        {open ? (
          <ul className={styles.dropdown}>
            {options.map((option, index) => (
              <li
                key={index}
                className={`${styles.option} ${
                  value === option.value ? styles.selected : ''
                }`}
                onClick={() => {
                  onChange(option.value);
                  setOpen(false);
                }}
              >
                {value === option.value && <div className={styles.bullet} />}
                <span>{option.label}</span>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
