import { createContext, useState, FC, ReactNode, useContext } from 'react';
import { ModalOverlay } from '../components/ModalOverlay';

interface ModalContextProps {
  content: ReactNode;
  setContent: (value: ReactNode) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalContext = createContext<ModalContextProps>({
  content: null,
  setContent: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
});

const ModalProvider: FC<ModalProviderProps> = ({
  children,
}: ModalProviderProps) => {
  const [content, setContent] = useState<ReactNode>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        content,
        setContent,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
      <ModalOverlay
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setContent(null);
        }}
      >
        {content}
      </ModalOverlay>
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModalContext = () => {
  return useContext(ModalContext);
};
