import { FC, SVGProps } from 'react';

const Burger: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 6.5H16.3M1 1.39999H16.3M1 11.6H16.3"
        stroke="#currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.5H16.3M1 1.39999H16.3M1 11.6H16.3"
        stroke="url(#paint0_linear_1518_325)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1518_325"
          x1="8.65"
          y1="1.39999"
          x2="8.65"
          y2="11.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Burger;
