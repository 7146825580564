import { TokenItem, TokenItemV2 } from '../interfaces/token';

export function convertTokenItemToTokenItemV2(
  tokenItem: TokenItem
): TokenItemV2 {
  return {
    mint: tokenItem.token.mint,
    value: tokenItem.token.meta.symbol,
    changes: '',
    tokenStats: tokenItem.stats,
    tokenDetails: {
      mint: tokenItem.token.mint,
      creator: tokenItem.token.creator,
      meta: tokenItem.token.meta,
      metadataUrl: tokenItem.token.metadataUrl,
      transaction_signature: tokenItem.token.transaction_signature,
      instruction_index: tokenItem.token.instruction_index,
      internal_instruction_index: tokenItem.token.internal_instruction_index,
      time: tokenItem.token.time,
      released_at: tokenItem.token.released_at as string | null,
      id: tokenItem.token.id,
      created_at: tokenItem.token.created_at as string | null,
      updated_at: tokenItem.token.updated_at as string | null,
    },
  };
}
