import { FC } from 'react';
import { VirtualTable } from '../../components/VirtualTable';
import { createColumnHelper } from '@tanstack/react-table';
import styles from './styles.module.css';
import { Order } from '../../interfaces/token';
import { roundN } from '../../utils/round-number';
import moment from 'moment';

interface TokenTableProps {
  data?: Order[];
  tokenName?: string;
}

export const TransactionTable: FC<TokenTableProps> = (
  props: TokenTableProps
) => {
  const { data = [], tokenName = 'Token' } = props;

  const columnHelper = createColumnHelper<Order>();
  const tableColumns = [
    columnHelper.accessor('maker', {
      header: 'Account',
      size: 200,
      cell: (value) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            className={styles.cell}
          >
            {value.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor('token_mint', {
      header: 'Type',
      cell: (value) => {
        return (
          <div
            className={`${
              styles[
                value.row.original.quote_amount_in === null ? 'sell' : 'buy'
              ]
            } ${styles.cell}`}
          >
            {value.row.original.quote_amount_in === null ? 'Sell' : 'Buy'}
          </div>
        );
      },
    }),
    columnHelper.accessor('quote_amount_out', {
      header: 'Sol',
      sortingFn: (a, b) =>
        Number(a.original.quote_amount_in || a.original.quote_amount_out) <
        Number(b.original.quote_amount_in || b.original.quote_amount_out)
          ? -1
          : 1,
      cell: (value) => {
        return (
          <div
            className={`${
              styles[
                value.row.original.quote_amount_in === null ? 'sell' : 'buy'
              ]
            } ${styles.cell}`}
          >
            {roundN(
              Number(value.row.original.quote_amount_in) / 1000000000 ||
                Number(value.row.original.quote_amount_out) / 1000000000,
              2
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('token_amount_in', {
      header: tokenName,
      sortingFn: (a, b) =>
        Number(a.original.token_amount_in || a.original.token_amount_out) <
        Number(b.original.token_amount_in || b.original.token_amount_out)
          ? -1
          : 1,
      cell: (value) => {
        return (
          <div
            className={`${
              styles[
                value.row.original.quote_amount_in === null ? 'sell' : 'buy'
              ]
            } ${styles.cell}`}
          >
            {roundN(
              Number(value.row.original.token_amount_in) / 1000000 ||
                Number(value.row.original.token_amount_out) / 1000000,
              2
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('time', {
      header: 'Date',
      size: 213,
      cell: (value) => {
        return (
          <div className={styles.cell}>
            {moment(value.getValue()).fromNow()}
          </div>
        );
      },
    }),
    columnHelper.accessor('transaction_signature', {
      header: 'Transaction',
      size: 200,
      cell: (value) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            className={styles.cell}
          >
            {value.getValue()}
          </div>
        );
      },
    }),
  ];

  return (
    <VirtualTable
      data={data}
      columns={tableColumns}
      justify="center"
      rowClassName={styles.row}
    />
  );
};
