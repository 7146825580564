import { useEffect, useState } from 'react';
import { BaseWalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { Row } from '../Row';
import Plus from '../svg/Plus';
import Telegram from '../svg/Telegram';
import Twitter from '../svg/Twitter';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { LABELS } from '../../services/web3Service';
import Plus24 from '../svg/Plus24';
// import Search22 from '../svg/Search22';
import Burger25 from '../svg/Burger25';
import Close from '../svg/Close';
import { useWindowSize } from '../../hooks/useWindowSize';
// import { useSearchContext } from '../../providers/search';

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const { isSearchOpen, setIsSearchOpen } = useSearchContext();
  const { width } = useWindowSize();
  useEffect(() => {
    if (width > 768) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);
  return (
    <>
      {isMobileMenuOpen && (
        <div className={styles.mobileMenu}>
          <div className={styles.mobileMenuHeader}>
            <div className={styles.connectWalletButtonMobile}>
              <BaseWalletMultiButton
                style={{
                  background: 'transparent',
                  borderRadius: 100,
                  height: '43px',
                  color: 'white',
                  padding: '12px 17px',
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  fontWeight: 500,
                  fontFamily: 'inherit',
                }}
                labels={LABELS}
              />
            </div>
            <Close onClick={() => setIsMobileMenuOpen(false)} />
          </div>
          <div className={styles.mobileNavList}>
            <a href="/user/1" className={styles.mobileLink}>
              Profile
            </a>
            <a href="/support" className={styles.mobileLink}>
              Support
            </a>
            <a href="/docs" className={styles.mobileLink}>
              Docs
            </a>
            <a href="/twitter" className={styles.mobileLink}>
              Twitter
            </a>
            <a href="/telegram" className={styles.mobileLink}>
              Telegram
            </a>
          </div>
        </div>
      )}
      <header className={`${styles.container}`}>
        <Container>
          <Row>
            <Col size={4} className={styles.logoLinksWrapper}>
              <Link to="/">
                <img src="/logo.svg" alt="logo" className={styles.logo} />
              </Link>
              <nav className={styles.nav}>
                <a href="/docs" className={styles.link}>
                  docs
                </a>
                <a href="/support" className={styles.link}>
                  support
                </a>
              </nav>
            </Col>
            <Col size={4} className={styles.createWrapper}>
              <Link to="/create" className={styles.linkReset}>
                <Button className={styles.createTokenButton}>
                  <span>create token</span>
                  <Plus className={styles.plus35} />
                  <Plus24 className={styles.plus24} />
                </Button>
              </Link>
            </Col>
            <Col size={4} className={styles.connectWrapper}>
              <div className={styles.socialLinks}>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/4dotfun"
                >
                  <Twitter />
                </a>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/fordotfun"
                >
                  <Telegram />
                </a>
              </div>
              <div className={styles.connectWalletButton}>
                <BaseWalletMultiButton
                  style={{
                    background: 'transparent',
                    borderRadius: 100,
                    height: '56px',
                    color: 'white',
                    padding: '20px',
                    fontSize: '16px',
                    lineHeight: '18.24px',
                    fontWeight: 500,
                    fontFamily: 'inherit',
                  }}
                  labels={LABELS}
                />
              </div>
              <div className={styles.menuWrapper}>
                {/* <div
                  className={styles.menuElement}
                  onClick={() => setIsSearchOpen(!isSearchOpen)}
                >
                  {isSearchOpen ? <Close /> : <Search22 />}
                </div> */}
                <div
                  className={styles.menuElement}
                  onClick={() => setIsMobileMenuOpen(true)}
                >
                  <Burger25 />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
