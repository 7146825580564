import { FC, ReactNode } from 'react';
import { Header } from '../Header';
import styles from './styles.module.css';
import { RefcodeProvider } from '../../providers/refcode';
import { Toaster } from 'react-hot-toast';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props: LayoutProps) => {
  const { children } = props;

  return (
    <RefcodeProvider>
      <div className={styles.container}>
        <Header />
        <main className={styles.main}>{children}</main>
      </div>
      <Toaster
        position="bottom-right"
        toastOptions={{
          icon: null,
          style: {
            backgroundColor: '#252424',
            color: 'white',
            borderRadius: 15,
            borderWidth: 1,
            borderStyle: 'solid',
            padding: 16,
            fontSize: '15px',
            lineHeight: '19.5px',
            fontFamily: 'Pangram',
            fontWeight: 500,
            maxWidth: 'unset',
          },
          success: {
            style: { borderColor: '#00FF94' },
          },
          error: {
            style: { borderColor: '#FC6868' },
          },
        }}
      />
    </RefcodeProvider>
  );
};
