import { useCallback, useState, FC } from 'react';
import { Table } from '../../components/Table';
import { createColumnHelper } from '@tanstack/react-table';
import styles from './styles.module.css';
import { Avatar } from '../../components/Avatar';
import Twitter from '../../components/svg/Twitter';
import Telegram from '../../components/svg/Telegram';
import Discord from '../../components/svg/Discord';
import Copy from '../../components/svg/Copy';
import { ProgressBar } from '../../components/ProgressBar';
import { Link } from 'react-router-dom';
import { TokenItemV2 } from '../../interfaces/token';
import moment from 'moment';
import { roundN } from '../../utils/round-number';
interface TokenTableProps {
  data?: TokenItemV2[];
}

export const TokenTable: FC<TokenTableProps> = (props: TokenTableProps) => {
  const { data = [] } = props;
  const [copyId, setCopyId] = useState<string | null>(null);

  const copy = useCallback(
    (value: string, id: string) => {
      navigator.clipboard.writeText(value);
      setCopyId(id);
      setTimeout(() => {
        setCopyId(null);
      }, 1000);
    },
    [setCopyId]
  );

  const generateTokenLink = useCallback((id: string) => `/${id}`, []);

  const columnHelper = createColumnHelper<TokenItemV2>();
  const tableColumns = [
    columnHelper.accessor('tokenDetails.meta.name', {
      header: 'Token',
      size: 200,
      meta: {
        justifyContent: 'flex-start',
      },
      sortingFn: (a, b) =>
        a.original.tokenDetails.meta.name > b.original.tokenDetails.meta.name
          ? 1
          : -1,
      cell: (props) => {
        return (
          <div className={styles.tokenNameWrapper}>
            <Link
              className={styles.tokenName}
              to={generateTokenLink(props.row.original.mint)}
            >
              <Avatar
                src={props.row.original.tokenDetails.meta.imageUrl}
                size={47}
                color="#B6B1AF"
                type="circle"
              />
            </Link>
            <div className={styles.tokenNameContainer}>
              <Link
                className={styles.tokenName}
                to={generateTokenLink(props.row.original.mint)}
              >
                {props.getValue()} (
                {props.row.original.tokenDetails.meta.symbol})
              </Link>
              <div className={styles.socialLinks}>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com"
                >
                  <Twitter width={16} height={16} />
                </a>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://telegram.org"
                >
                  <Telegram width={16} height={16} />
                </a>
                <a
                  className={styles.socialLink}
                  href={'https://discord.com'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Discord />
                </a>
              </div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('mint', {
      header: 'CA',
      size: 100,
      enableSorting: false,
      cell: (value) => {
        return (
          <div
            className={styles.smartContract}
            style={
              copyId === value.row.original.mint
                ? {
                    color: '#00ff94',
                  }
                : {}
            }
            onClick={() => copy(value.getValue(), value.row.original.mint)}
          >
            <Copy style={{ minWidth: 17 }} />
            <span>
              &nbsp;
              {value.getValue()}
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor('tokenDetails.created_at', {
      header: 'Created',
      size: 120,
      cell: (value) => {
        return moment(value.getValue()).fromNow();
      },
    }),
    columnHelper.accessor('tokenStats.marketCap', {
      header: 'MCAP',
      size: 85,
      sortingFn: (a, b) =>
        Number(a.original.tokenStats.marketCap) <
        Number(b.original.tokenStats.marketCap)
          ? -1
          : 1,
      cell: (value) => {
        return `$${roundN(Number(value.getValue()), 2)}`;
      },
    }),
    columnHelper.accessor('tokenStats.liquidity', {
      header: 'Liquidity',
      size: 85,
      sortingFn: (a, b) =>
        Number(a.original.tokenStats.liquidity) <
        Number(b.original.tokenStats.liquidity)
          ? -1
          : 1,
      cell: (value) => {
        return `${roundN(Number(value.getValue()) / 1000000000, 2)}SOL`;
      },
    }),
    columnHelper.accessor('tokenStats.makers', {
      header: 'Makers',
      size: 75,
      sortingFn: (a, b) =>
        Number(a.original.tokenStats.makers) <
        Number(b.original.tokenStats.makers)
          ? -1
          : 1,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.transactions', {
      header: 'TXNS',
      size: 85,
      sortingFn: (a, b) =>
        Number(
          a.original.tokenStats.transactions.sell +
            a.original.tokenStats.transactions.buy
        ) <
        Number(
          b.original.tokenStats.transactions.sell +
            b.original.tokenStats.transactions.buy
        )
          ? -1
          : 1,
      cell: (value) => {
        return (
          <div className={styles.transactions}>
            <div className={styles.totalTransactions}>
              {value.getValue().buy + value.getValue().sell}
            </div>
            <div className={styles.transactionsChanges}>
              <div className={styles.transactionsUp}>
                {value.getValue().buy}
              </div>
              <div className={styles.transactionsDown}>
                {value.getValue().sell}
              </div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('tokenStats.volume', {
      header: 'Volume',
      size: 85,
      sortingFn: (a, b) =>
        Number(a.original.tokenStats.volume) <
        Number(b.original.tokenStats.volume)
          ? -1
          : 1,
      cell: (value) => {
        return `${roundN(Number(value.getValue()) / 1000000000, 2)}SOL`;
      },
    }),
    columnHelper.accessor('tokenStats.changes.period60s', {
      header: '1m',
      size: 100,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.changes.period5m', {
      header: '5m',
      size: 100,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.changes.period15m', {
      header: '15m',
      size: 100,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.changes.period30m', {
      header: '30m',
      size: 100,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.changes.period1h', {
      header: '1h',
      size: 100,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.threads', {
      header: 'Comments',
      size: 75,
      cell: (value) => {
        return value.getValue();
      },
    }),
    columnHelper.accessor('tokenStats.bondingRate', {
      header: 'Bonding',
      size: 110,
      cell: (value) => {
        return <ProgressBar progress={Math.round(value.getValue() * 100)} />;
      },
    }),
    columnHelper.accessor('tokenDetails.mint', {
      header: 'Actions',
      size: 75,
      enableSorting: false,
      cell: () => {
        return '';
      },
    }),
  ];

  return (
    <Table
      data={data}
      columns={tableColumns}
      justify="center"
      rowClassName={styles.row}
      wrapperClassName={styles.tableWrapper}
    />
  );
};
