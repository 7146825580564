import { FC, SVGProps } from 'react';

const Stack: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.3 2.79999C16.3 3.91245 12.875 4.81428 8.65 4.81428C4.42502 4.81428 1 3.91245 1 2.79999M16.3 2.79999C16.3 1.68753 12.875 0.785706 8.65 0.785706C4.42502 0.785706 1 1.68753 1 2.79999M16.3 2.79999V12.2C16.3 13.3146 12.9 14.2143 8.65 14.2143C4.4 14.2143 1 13.3146 1 12.2V2.79999M16.3 7.5C16.3 8.61457 12.9 9.51429 8.65 9.51429C4.4 9.51429 1 8.61457 1 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3 2.79999C16.3 3.91245 12.875 4.81428 8.65 4.81428C4.42502 4.81428 1 3.91245 1 2.79999M16.3 2.79999C16.3 1.68753 12.875 0.785706 8.65 0.785706C4.42502 0.785706 1 1.68753 1 2.79999M16.3 2.79999V12.2C16.3 13.3146 12.9 14.2143 8.65 14.2143C4.4 14.2143 1 13.3146 1 12.2V2.79999M16.3 7.5C16.3 8.61457 12.9 9.51429 8.65 9.51429C4.4 9.51429 1 8.61457 1 7.5"
        stroke="url(#paint0_linear_1518_329)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1518_329"
          x1="8.65"
          y1="0.785706"
          x2="8.65"
          y2="14.2143"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Stack;
