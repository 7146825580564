import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import Search22 from '../svg/Search22';
import { useDataContext } from '../../providers/data';

export const Search = () => {
  const { setSearchString } = useDataContext();
  const [searchValue, setSearchValue] = useState('');

  const useDebounce = (callback: (value: string) => void, delay: number) => {
    const timer = useRef<NodeJS.Timeout | null>(null);

    return useCallback(
      (value: string) => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
          callback(value);
        }, delay);
      },
      [callback, delay]
    );
  };

  const debouncedSetSearchString = useDebounce(setSearchString, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value || '');
  };

  useEffect(() => {
    debouncedSetSearchString(searchValue);
  }, [searchValue, debouncedSetSearchString]);

  return (
    <div className={styles.search}>
      <Search22 />
      <input
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={handleChange}
      />
    </div>
  );
};
