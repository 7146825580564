import { useCallback, useEffect, useState } from 'react';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Col } from '../../components/Col';
import { Container } from '../../components/Container';
import { List } from '../../components/List';
import { Row } from '../../components/Row';
import { Select } from '../../components/Select';
import ArrowUp from '../../components/svg/ArrowUp';
import Burger from '../../components/svg/Burger';
import styles from './styles.module.css';
import Stack from '../../components/svg/Stack';
import Persons from '../../components/svg/Persons';
import Cloud from '../../components/svg/Cloud';
// import Rocket from '../../components/svg/Rocket';
// import { Input } from '../../components/Input';
// import Search from '../../components/svg/Search';
import { ProgressBar } from '../../components/ProgressBar';
import CloudBig from '../../components/svg/CloudBig';
// import Zap from '../../components/svg/Zap';
import { TokenTable } from '../../containers/TokenTable';
import { TokenItemV2 } from '../../interfaces/token';
import { useDataContext } from '../../providers/data';
import { Link } from 'react-router-dom';
// import { ToggleSwitch } from '../../components/ToggleSwitch';
import Persons13 from '../../components/svg/Persons13';
import Cloud12 from '../../components/svg/Cloud12';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Search } from '../../components/Search';
import Burger10 from '../../components/svg/Burger10';
import Stack11 from '../../components/svg/Stack11';
import moment from 'moment';
import { roundN } from '../../utils/round-number';

export const Root = () => {
  const [block, setBlock] = useState(0);
  const {
    topBoundingRateTokens,
    topKOTHTokens,
    topLatestTokens,
    latestTokens,
    topRecentTokens,
    topTransactionCountTokens,
    topMarketCapTokens,
    // autoUpdate,
    // setAutoUpdate,
    fetchData,
    sortField,
    setSortField,
    sortType,
    setSortType,
  } = useDataContext();
  const { width } = useWindowSize();
  const generateTokenLink = useCallback((id: string) => `/${id}`, []);

  const getChangesColor = (value: number) => {
    if (value > 0) {
      return '#00FF94';
    } else if (value < 0) {
      return '#fc6868';
    } else {
      return 'inherit';
    }
  };

  const getArrowDirection = (value: number) => {
    if (value > 0) {
      return <ArrowUp style={{ marginRight: 4 }} />;
    } else if (value < 0) {
      return (
        <ArrowUp style={{ marginRight: 4, transform: 'rotate(180deg)' }} />
      );
    } else {
      return '';
    }
  };

  const renderDataItem = (item: TokenItemV2) => (
    <div className={styles.item}>
      <Link className={styles.name} to={generateTokenLink(item.mint)}>
        <Avatar
          src={item.tokenDetails.meta.imageUrl}
          size={36}
          type="circle"
          className={styles.avatarHovered}
        />
        <span>
          {item.tokenDetails.meta.name} ({item.tokenDetails.meta.symbol})
        </span>
      </Link>
      <div
        className={styles.price}
        style={{ color: getChangesColor(Number(item.changes)) }}
      >
        {roundN(Number(item.value), 6)}
      </div>
      <div
        className={styles.change}
        style={{ color: getChangesColor(Number(item.changes)) }}
      >
        {getArrowDirection(Number(item.changes))}
        {' ' + roundN(Number(item.changes), 2)}%
      </div>
    </div>
  );

  const renderTokenItem = (item: TokenItemV2) => (
    <Link
      className={styles.tokenHeader}
      to={generateTokenLink(item.tokenDetails.mint)}
    >
      <Avatar
        src={item.tokenDetails.meta.imageUrl}
        size={85}
        type="rounded"
        className={styles.avatarHovered}
      />
      <div className={styles.tokenInfo}>
        <div className={styles.tokenName}>
          {item.tokenDetails.meta.name} ({item.tokenDetails.meta.symbol})
        </div>
        <div className={styles.tokenDev}>{item.tokenDetails.creator}</div>
        <div className={styles.tokenMarketCap}>
          Mcap:{' '}
          <span className={styles.green}>{item.tokenStats.marketCap}</span>
        </div>
        <div className={styles.tokenReplies}>
          Replies:{' '}
          <span className={styles.green}>{item.tokenStats.threads}</span>
        </div>
      </div>
    </Link>
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <Search />
      <Container>
        <Row className={styles.topRow}>
          <Col size={4} md={{ size: 6 }}>
            <Card>
              <h3 className={styles.cardName}>Top Gainers</h3>
              <List
                data={topBoundingRateTokens.slice(0, 5)}
                renderItem={renderDataItem}
                ordered
              />
            </Card>
          </Col>
          <Col size={4} md={{ size: 6 }}>
            <Card>
              <h3 className={styles.cardName}>Top Transactions</h3>
              <List
                data={topTransactionCountTokens.slice(0, 5)}
                renderItem={renderDataItem}
                ordered
              />
            </Card>
          </Col>
          <Col size={4} md={{ size: 12 }}>
            <Card>
              <h3 className={styles.cardName}>KOTH</h3>
              <List
                data={topKOTHTokens.slice(0, 5)}
                renderItem={renderDataItem}
                ordered
              />
            </Card>
          </Col>
        </Row>
        <div className={styles.row}>
          <div className={styles.buttonRow}>
            <Select
              prefix={
                <span className={styles.prefix}>
                  <Burger className={styles.selectIcon} />
                  <Burger10 className={styles.selectIconMobile} />
                  order by&nbsp;
                </span>
              }
              value={sortType}
              options={[
                { label: 'asc', value: 'asc' },
                { label: 'desc', value: 'desc' },
              ]}
              onChange={setSortType}
            />
            <Select
              prefix={
                <span className={styles.prefix}>
                  <Stack
                    className={styles.filterIcon}
                    style={{ marginRight: 8 }}
                  />
                  <Stack11
                    className={styles.filterIconMobile}
                    style={{ marginRight: 8 }}
                  />
                  sort by&nbsp;
                </span>
              }
              value={sortField}
              options={[
                { label: 'creation time', value: 'created_at' },
                { label: 'bump order', value: 'latest_order_time' },
                { label: 'market cap', value: 'latest_order_mcap' },
              ]}
              onChange={setSortField}
            />
            {/* <ToggleSwitch
              label="Auto-update"
              value={autoUpdate}
              onChange={setAutoUpdate}
              labelStyle={styles.toggleLabel}
              containerStyle={styles.toggleContainer}
            /> */}
            <Button
              className={`${styles.filterButton} ${
                block === 0 ? styles.filterButtonSelected : ''
              }`}
              onClick={() => setBlock(0)}
            >
              <Persons className={styles.filterIcon} />
              <Persons13 className={styles.filterIconMobile} />
              latest
            </Button>
            <Button
              className={`${styles.filterButton} ${
                block === 1 ? styles.filterButtonSelected : ''
              }`}
              onClick={() => setBlock(1)}
            >
              <Cloud className={styles.filterIcon} />
              <Cloud12 className={styles.filterIconMobile} />
              trending
            </Button>
            {/* <Button
              className={
                styles[block === 2 ? 'filterButtonSelected' : 'filterButton']
              }
              onClick={() => setBlock(2)}
            >
              <Rocket style={{ color: '#00ff94' }} />
              recently added
            </Button> */}
            {/* <Input
              placeholder="search your token"
              prefix={<Search />}
              className={styles.search}
            /> */}
          </div>
          <div className={styles.recentWrapper}>
            <span>
              🔥 added{' '}
              {moment(topLatestTokens[0]?.tokenDetails.created_at).fromNow()}
            </span>
            <Link to={generateTokenLink(topLatestTokens[0]?.mint)}>
              <Avatar
                src={topLatestTokens[0]?.tokenDetails.meta.imageUrl}
                size={58}
                type="circle"
                noborder
              />
            </Link>
          </div>
        </div>
        <Row>
          <Col size={9} lg={{ size: 8 }} md={{ size: 12 }}>
            <Row className={styles.tokens}>
              {[latestTokens, topTransactionCountTokens, topMarketCapTokens][
                block
              ]
                .slice(0, 12)
                .map((item, i) => (
                  <Col
                    size={4}
                    lg={{ size: 6 }}
                    md={{ size: 12 }}
                    key={i + '_token'}
                    className={styles.tokenMainList}
                  >
                    <Card
                      className={styles.token}
                      link={generateTokenLink(item.mint)}
                    >
                      <div className={styles.tokenHeader}>
                        <Avatar
                          src={item.tokenDetails.meta.imageUrl}
                          size={width <= 1024 ? 68 : 110}
                          type="rounded"
                          className={styles.avatarHovered}
                        />
                        <div className={styles.tokenInfo}>
                          <div className={styles.tokenName}>
                            {item.tokenDetails.meta.name} (
                            {item.tokenDetails.meta.symbol})
                          </div>
                          <div className={styles.tokenDev}>
                            {item.tokenDetails.creator}
                          </div>
                          <div className={styles.tokenStats}>
                            <div className={styles.tokenMarketCap}>
                              {width <= 1024 ? 'MC' : 'Mcap'}:{' '}
                              <span className={styles.green}>
                                {item.tokenStats.marketCap}
                              </span>
                            </div>
                            <div className={styles.tokenReplies}>
                              Replies:{' '}
                              <span className={styles.green}>
                                {item.tokenStats.threads}
                              </span>
                            </div>
                          </div>
                          <ProgressBar
                            progress={Math.round(
                              item.tokenStats.bondingRate * 100
                            )}
                            className={styles.tokenProgress}
                            labelClassName={styles.tokenProgressLabel}
                          />
                        </div>
                      </div>
                      <div className={styles.tokenDescription}>
                        {item.tokenDetails.meta.description}
                      </div>
                    </Card>
                  </Col>
                ))}
              {[latestTokens, topTransactionCountTokens, topMarketCapTokens][
                block
              ].slice(0, 12).length === 0 && (
                <div className={styles.noData}>No data</div>
              )}
            </Row>
          </Col>
          <Col
            size={3}
            lg={{ size: 4 }}
            md={{ size: 6 }}
            className={styles.newThreeSol}
          >
            <Card className={styles.recentTokens}>
              <div className={styles.recentLabel}>
                <CloudBig style={{ marginRight: 20, color: '#00ff94' }} />
                New 3 sol in pool
              </div>
              <List
                data={topRecentTokens.slice(0, 9)}
                renderItem={renderTokenItem}
                gap={20}
                itemClassName={styles.itemToken}
              />
            </Card>
          </Col>
        </Row>
        <div className={styles.table}>
          <div className={styles.tableName}>Most Active</div>
          <Row>
            <Col size={12}>
              <TokenTable data={topLatestTokens.slice(0, 15)} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
