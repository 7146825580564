import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import styles from './styles.module.css';
import { FC, useState } from 'react';
import ArrowUpFill from '../svg/ArrowUpFill';
import ArrowDownFill from '../svg/ArrowDownFill';
interface TableProps {
  data: any[];
  columns: any[];
  onClick?: (data: any) => void;
  justify?: 'flex-start' | 'center' | 'flex-end';
  headerStyle?: any;
  rowClassName?: any;
  wrapperClassName?: any;
}
export const Table: FC<TableProps> = (props: TableProps) => {
  const {
    data,
    columns,
    onClick,
    justify = 'flex-start',
    rowClassName = '',
    wrapperClassName = '',
    headerStyle = {},
  } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  console.log(data);
  return (
    <div className={`${styles.wrapper} ${wrapperClassName}`}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    ...{
                      justifyContent:
                        (header.column.columnDef.meta as any)?.justifyContent ||
                        justify,
                    },
                    ...headerStyle,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      style={{
                        display: 'flex',
                        gap: 7,
                        justifyContent:
                          (header.column.columnDef.meta as any)
                            ?.justifyContent || justify,
                        alignItems: 'center',
                        textDecoration: header.column.getIsSorted()
                          ? 'underline'
                          : 'none',
                      }}
                      className={header.column.getCanSort() ? styles.sort : ''}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <ArrowUpFill />,
                        desc: <ArrowDownFill />,
                      }[header.column.getIsSorted() as string] ?? (
                        <div className={styles.invisibleBox} />
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={styles.tbody}>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                if (!!onClick) {
                  onClick(row.original);
                }
              }}
              className={rowClassName}
              style={!!onClick ? { cursor: 'pointer' } : {}}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div style={{ display: 'flex', justifyContent: justify }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={columns.length} align="center">
                No data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
