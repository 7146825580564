import React, {useEffect} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";

interface RefcodeProviderProps {
	children?: React.ReactNode;
}
export const RefcodeProvider = (props: RefcodeProviderProps) => {
	const { children } = props;
	const location = useLocation();
	const [cookies, setCookie] = useCookies();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const refcode = queryParams.get('refcode');

		if (refcode) {
			setCookie('refcode', refcode, { path: '/' });
		}
	}, [location]);

	return <>{children}</>
}