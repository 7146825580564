import { Priceble } from './types';

export const getPrice = (priceble: Priceble): number | null => {
  let a, b;

  if (!priceble.quote_amount_in && !priceble.quote_amount_out) {
    return 0;
  }

  if (priceble.quote_amount_out && priceble.token_amount_in) {
    // buy
    a = BigInt(priceble.quote_amount_out);
    b = BigInt(priceble.token_amount_in);
  }

  if (priceble.quote_amount_in && priceble.token_amount_out) {
    a = BigInt(priceble.quote_amount_in);
    b = BigInt(priceble.token_amount_out);
  }

  if (!a || !b) {
    return null;
  }

  try {
    return Number((a * 1_000_000_000n) / b) / 1_000_000_000;
  } catch (error) {
    return null;
    // throw error;
  }
};
