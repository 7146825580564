import { PrivyProvider, usePrivy } from '@privy-io/react-auth';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useQuery } from '@tanstack/react-query';
import styles from './styles.module.css';
import Twitter from '../../components/svg/Twitter';
import Telegram from '../../components/svg/Telegram';
import Discord from '../../components/svg/Discord';
import { Button } from '../../components/Button';
import Copy from '../../components/svg/Copy';

const isDev = true; // @todo: isDev

const API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://api.arby.ai';

interface LoginContainerProps {
  data: any;
}

function LoginContainer({ data }: LoginContainerProps) {
  const { ready, authenticated, login, logout } = usePrivy();
  const disableLogin = !ready || (ready && authenticated);
  const [copied, setCopied] = useState(false);
  const copy = useCallback((value: string) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, []);
  useEffect(() => {
    let isModalOpen = false;
    if (!isModalOpen && !disableLogin) {
      isModalOpen = true;
      login();
    }
  }, [disableLogin]);
  return (
    <div className={styles.signInBlock}>
      {authenticated ? (
        <>
          <h1 className={styles.signInTitle}>INVITE FRENS</h1>
          <h2 className={styles.signInSubtitle}>
            EARLY BIRDS GET THE JUICIEST REWARDS
          </h2>
          {data?.ref_link && (
            <div className={styles.infoBlock}>
              <div
                className={styles.refLink}
                style={
                  copied
                    ? {
                        color: '#00ff94',
                      }
                    : {}
                }
                onClick={() => copy(data.ref_link)}
              >
                <span className={styles.refLinkText}>
                  <span> {data.ref_link.split('/?')[0]}/?</span>
                  <span>{data.ref_link.split('/?')[1]}</span>
                </span>
                <Copy style={{ minWidth: 17 }} />
              </div>
              <div className={styles.leadsAmount}>
                referrals <span>{data.leads_amount}</span>
              </div>
            </div>
          )}
          <Button
            type="submit"
            className={styles.signInButton}
            onClick={logout}
          >
            Log out
          </Button>
        </>
      ) : (
        <>
          <h1 className={styles.signInTitle}>REFER & ENJOY BOOSTED REWARDS</h1>
          <Button
            type="submit"
            className={styles.signInButton}
            onClick={login}
            disabled={disableLogin}
          >
            Sign in
          </Button>
        </>
      )}
    </div>
  );
}

export const Landing = () => {
  const [cookies] = useCookies();
  const [idToken, setIdToken] = useState<any>(null);
  const [refcode, setRefcode] = useState<any>(null);

  const getUserQuery = useQuery({
    queryKey: [`get-user:${idToken}:${refcode}`],
    queryFn: async () => {
      const params: any = {};

      if (isDev) {
        params.headers = {
          'privy-id-token': idToken,
          refcode: refcode,
        };
      }

      const response = await axios.post(
        `${API_URL}/backend/user/privy`,
        {
          wallet: 'qwe', // todo: change
        },
        params
      );
      return response.data;
    },
    retry: 3,
    enabled: !!idToken && refcode !== null,
  });

  useEffect(() => {
    setIdToken(cookies['privy-id-token']);
  }, [cookies['privy-id-token']]);

  useEffect(() => {
    setRefcode(cookies.refcode || false);
  }, [cookies.refcode]);

  console.log({ user: getUserQuery.data });

  return (
    <div className={styles.containerWrapper}>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 0,
        }}
        src="/background.mp4"
      />
      <PrivyProvider
        appId={'cm18absle0724i34u7kiinb6a'}
        config={{
          appearance: {
            accentColor: '#6A6FF5',
            theme: '#121111',
            showWalletLoginFirst: false,
            logo: 'https://4fun-prod.pages.dev/logo.svg',
            walletChainType: 'ethereum-and-solana',
          },
          loginMethods: ['twitter'],
          fundingMethodConfig: {
            moonpay: {
              useSandbox: true,
            },
          },
          embeddedWallets: {
            createOnLogin: 'off',
            requireUserPasswordOnCreate: false,
          },
          mfa: {
            noPromptOnMfaRequired: false,
          },
        }}
      >
        {/* <div>
          qwe
          <LoginButton />
          |
          <LogoutButton />
        </div>
        <hr />
        <div>
          <PrivyUserDetails />
          {getUserQuery.data?.ref_link && (
            <UserDetails
              refLink={getUserQuery.data.ref_link}
              leadsAmount={getUserQuery.data.leads_amount}
            />
          )}
        </div> */}
        <div className={styles.container}>
          <div className={styles.cardContainer}>
            <h1 className={styles.title}>
              meme-to-earn
              <br />
              4.fun soon!
            </h1>
            <p className={styles.text}>JOIN NOW - LIMITED SPOTS</p>
          </div>
          <div className={styles.verticalDivider} />
          <div className={styles.cardContainerMain}>
            <LoginContainer data={getUserQuery.data} />
          </div>
          <div className={styles.socialLinks}>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noreferrer"
              href="https://x.com/4dotfun"
            >
              <Twitter width={32} height={32} />
            </a>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noreferrer"
              href="https://t.me/fordotfun"
            >
              <Telegram width={32} height={32} />
            </a>
          </div>
        </div>
      </PrivyProvider>
    </div>
  );
};
