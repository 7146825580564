import { FC } from 'react';
import styles from './styles.module.css';

interface ILoaderProps {
  size?: number;
}

export const Loader: FC<ILoaderProps> = (props: ILoaderProps) => {
  const { size = 19 } = props;
  return (
    <div className={styles.container} style={{ width: size, height: size }}>
      <div className={styles.loader} />
    </div>
  );
};
