import {
  createContext,
  useEffect,
  useState,
  FC,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import {
  IndexSortField,
  IndexSortType,
  TokenItemV2,
} from '../interfaces/token';
import {
  fetchBoundingRateTokens,
  fetchKOTHTokens,
  fetchLatestTokens,
  fetchMarketCapTokens,
  fetchRecentlyAddedTokens,
  fetchTokens,
  fetchTransactionCountTokens,
} from '../services/tokenService';
import Pusher from 'pusher-js';

const PUSHER_APP_KEY =
  process.env.REACT_APP_PUSHER_APP_KEY || 'c7373849f192b69acbb4';

interface DataContextProps {
  topBoundingRateTokens: TokenItemV2[];
  topTransactionCountTokens: TokenItemV2[];
  topKOTHTokens: TokenItemV2[];
  topLatestTokens: TokenItemV2[];
  latestTokens: TokenItemV2[];
  topRecentTokens: TokenItemV2[];
  topMarketCapTokens: TokenItemV2[];
  setAutoUpdate: (value: boolean) => void;
  autoUpdate: boolean;
  isLoading: boolean;
  orderSubscribe: (token: string, cb: (data: any) => void) => void;
  orderUnsubscribe: (token: string) => void;
  tokenStatsSubscribe: (token: string, cb: (data: any) => void) => void;
  tokenStatsUnsubscribe: (token: string) => void;
  fetchData: () => void;
  searchString: string;
  setSearchString: (value: string) => void;
  sortField: IndexSortField;
  setSortField: (value: IndexSortField) => void;
  sortType: IndexSortType;
  setSortType: (value: IndexSortType) => void;
}

interface DataProviderProps {
  children: ReactNode;
}

export const DataContext = createContext<DataContextProps>({
  topBoundingRateTokens: [],
  topTransactionCountTokens: [],
  topKOTHTokens: [],
  topLatestTokens: [],
  latestTokens: [],
  topRecentTokens: [],
  topMarketCapTokens: [],
  setAutoUpdate: () => {},
  autoUpdate: true,
  isLoading: true,
  orderSubscribe: () => {},
  orderUnsubscribe: () => {},
  tokenStatsSubscribe: () => {},
  tokenStatsUnsubscribe: () => {},
  fetchData: () => {},
  searchString: '',
  setSearchString: () => {},
  sortField: 'created_at',
  setSortField: () => {},
  sortType: 'desc',
  setSortType: () => {},
});

const DataProvider: FC<DataProviderProps> = ({
  children,
}: DataProviderProps) => {
  const [topBoundingRateTokens, setTopBoundingRateTokens] = useState<
    TokenItemV2[]
  >([]);
  const [topTransactionCountTokens, setTopTransactionCountTokens] = useState<
    TokenItemV2[]
  >([]);
  const [topKOTHTokens, setTopKOTHTokens] = useState<TokenItemV2[]>([]);
  const [topLatestTokens, setTopLatestTokens] = useState<TokenItemV2[]>([]);
  const [latestTokens, setLatestTokens] = useState<TokenItemV2[]>([]);
  const [topRecentTokens, setTopRecentTokens] = useState<TokenItemV2[]>([]);
  const [topMarketCapTokens, setTopMarketCapTokens] = useState<TokenItemV2[]>(
    []
  );
  const dataFetchingDisabled = process.env.REACT_APP_SHOW_LANDING === '1';
  const [searchString, setSearchString] = useState<string>('');
  const [sortField, setSortField] = useState<IndexSortField>('created_at');
  const [sortType, setSortType] = useState<IndexSortType>('desc');
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const [channels, setChannels] = useState<Record<string, any>>({});

  const [autoUpdate, setAutoUpdate] = useState<boolean>(true);
  const fetchData = async () => {
    const [
      dataTopBoundingRateTokens,
      dataTopTransactionCountTokens,
      dataTopKOTHTokens,
      dataTopLatestTokens,
      dataTopRecentTokens,
      dataTopMarketCapTokens,
      dataFetchTokens,
    ] = await Promise.all([
      fetchBoundingRateTokens(),
      fetchTransactionCountTokens(),
      fetchKOTHTokens(),
      fetchLatestTokens(),
      fetchRecentlyAddedTokens(),
      fetchMarketCapTokens(),
      fetchTokens(12, searchString, sortField, sortType),
    ]);
    setTopBoundingRateTokens(dataTopBoundingRateTokens);
    setTopTransactionCountTokens(dataTopTransactionCountTokens);
    setTopKOTHTokens(dataTopKOTHTokens);
    setTopLatestTokens(dataTopLatestTokens);
    setTopRecentTokens(dataTopRecentTokens);
    setTopMarketCapTokens(dataTopMarketCapTokens);
    setLatestTokens(dataFetchTokens);
  };

  useEffect(() => {
    if (dataFetchingDisabled) {
      return;
    }
    fetchData();

    Pusher.logToConsole = true;
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: 'eu',
    });
    pusher.connection.bind('error', function (err: any) {
      if (err.data?.code === 4004) {
        console.log('Over limit!');
      }
    });
    const channel = pusher.subscribe('token');

    channel.bind('token:created', function (data: any) {
      console.log('token/token:created', JSON.stringify(data));
    });

    channel.bind('token:top-table-changed', function (data: any) {
      console.log('token/token:top-table-changed', JSON.stringify(data));
    });
    setPusher(pusher);

    return () => {
      pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    if (dataFetchingDisabled) {
      return;
    }
    (async function () {
      const data = await fetchTokens(12, searchString, sortField, sortType);
      setLatestTokens(data);
    })();
  }, [searchString, sortField, sortType, dataFetchingDisabled]);

  const orderSubscribe = useCallback(
    (token: string, cb: (data: any) => void) => {
      console.log('orderSubscribe', token);
      console.log('orderSubscribe', pusher);
      if (!pusher) {
        return;
      }

      const channel = pusher.subscribe(`order-${token}`);
      channel.bind('order:created', cb);

      setChannels((prev) => ({ ...prev, [`order-${token}`]: channel }));
    },
    [pusher]
  );

  const orderUnsubscribe = useCallback(
    (token: string) => {
      if (!pusher || !channels) {
        return;
      }

      const { [`order-${token}`]: channel, ...rest } = channels;
      if (!channel) {
        return;
      }
      console.log('orderUnsubscribe', channel);
      channel.unsubscribe();

      setChannels(rest);
    },
    [pusher, channels]
  );

  const tokenStatsSubscribe = useCallback(
    (token: string, cb: (data: any) => void) => {
      console.log('tokenStatsSubscribe', token);
      console.log('tokenStatsSubscribe', pusher);
      if (!pusher) {
        return;
      }

      const channel = pusher.subscribe(`token-${token}`);
      channel.bind('token:stats-changed', cb);

      setChannels((prev) => ({ ...prev, [`token-${token}`]: channel }));
    },
    [pusher]
  );

  const tokenStatsUnsubscribe = useCallback(
    (token: string) => {
      if (!pusher || !channels) {
        return;
      }

      const { [`token-${token}`]: channel, ...rest } = channels;
      if (!channel) {
        return;
      }
      console.log('tokenStatsUnsubscribe', channel);
      channel.unsubscribe();

      setChannels(rest);
    },
    [pusher, channels]
  );

  return (
    <DataContext.Provider
      value={{
        topBoundingRateTokens,
        topKOTHTokens,
        topLatestTokens,
        latestTokens,
        topRecentTokens,
        topTransactionCountTokens,
        topMarketCapTokens,
        setAutoUpdate,
        autoUpdate,
        isLoading: pusher === null,
        orderSubscribe,
        orderUnsubscribe,
        tokenStatsSubscribe,
        tokenStatsUnsubscribe,
        fetchData,
        searchString,
        setSearchString,
        sortField,
        setSortField,
        sortType,
        setSortType,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

export const useDataContext = () => {
  return useContext(DataContext);
};
