import { createContext, useState, FC, ReactNode, useContext } from 'react';

interface SearchContextProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  isSearchOpen: boolean;
  setIsSearchOpen: (value: boolean) => void;
}

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchContext = createContext<SearchContextProps>({
  searchValue: '',
  setSearchValue: () => {},
  isSearchOpen: false,
  setIsSearchOpen: () => {},
});

const SearchProvider: FC<SearchProviderProps> = ({
  children,
}: SearchProviderProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        isSearchOpen,
        setIsSearchOpen,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;

export const useSearchContext = () => {
  return useContext(SearchContext);
};
