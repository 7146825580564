import { ReactNode, FC } from 'react';
import styles from './styles.module.css';

interface ModalOverLayProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalOverlay: FC<ModalOverLayProps> = (
  props: ModalOverLayProps
) => {
  const { children, isOpen, onClose } = props;
  return isOpen ? (
    <div className={styles.overlayContainer}>
      <div className={styles.backdrop} onClick={onClose} />
      {children}
    </div>
  ) : null;
};
